import { parseCookies } from "nookies";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faUser,
  faEyeSlash,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Link from "next/link";
import { useRouter } from "next/router";
import { setCookie } from "nookies";
import { useForm } from "react-hook-form";
import { useState } from "react";
import Head from "next/head";
import API_URL from "../constants/api";
import logo from "../public/images/topo_logotipo.png";
import Image from "next/image";
import { ChangeTheme } from "../src/components/ChangeTheme";

export async function getServerSideProps(context) {
  const { ["auth_token"]: token } = parseCookies(context);

  if (token) {
    return {
      redirect: {
        destination: "/dashboard",
        permanent: false,
      },
    };
  }

  return {
    props: {},
  };
}

function HomePage() {
  const router = useRouter();
  const { register, handleSubmit } = useForm();
  const [viewUser, setViewUser] = useState(false);

  function handleSignIn({ email, password }) {
    const objUsuario = { email, password };
    if (email !== "" && password !== "") {
      try {
        axios
          .post(`${API_URL}/auth/authenticate`, objUsuario)
          .then((response) => {
            setCookie(undefined, "auth_token", response.data.token, {
              maxAge: 60 * 60 * 7, // 1 hora
            });
            setCookie(undefined, "email", response.data.user.email);
            localStorage.setItem("username", response.data.user.name);
            router.push("/dashboard");
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <>
      <Head>
        <title>Entrar</title>
      </Head>
      <div className="bg-gray-300 dark:bg-darkMode-300 absolute w-full h-full min-h-full">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 bg-gray-300 dark:bg-darkMode-300 min-h-full h-full flex items-center justify-center">
          <div className="w-[90%] max-w-md grid grid-cols-12 gap-8 bg-white shadow-lg dark:bg-darkMode-500 text-white p-8 mx-auto rounded-lg">
            <div className="relative col-span-12 flex items-center justify-center">
              <Image
                src={logo}
                width={80}
                height={68}
                className="h-fit block"
                alt="Logo Oznkb"
              />
              <ChangeTheme />
            </div>
            <form
              onSubmit={handleSubmit(handleSignIn)}
              className="col-span-12 flex flex-col gap-4"
            >
              <div className="flex w-full">
                <div className="bg-gray-300 dark:bg-darkMode-300 px-4 py-3 rounded-l-md border-r-2 border-white dark:border-r-darkMode-500">
                  <FontAwesomeIcon
                    icon={faUser}
                    className="text-gray-600 dark:text-gray-400"
                  />
                </div>
                <input
                  {...register("email")}
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="off"
                  required
                  className="text-sm w-full bg-gray-300 dark:bg-darkMode-300 text-primary-500 dark:text-gray-400 placeholder:text-gray-600 dark:placeholder:text-gray-400 font-poppins px-4 py-3 rounded-r-md outline-none border border-gray-300 dark:border-darkMode-300 dark:focus:border-blue-400"
                  placeholder="Email address"
                />
              </div>
              <div className="flex col-span-12 relative">
                <div className="bg-gray-300 dark:bg-darkMode-300 px-4 py-3 rounded-l-md border-r-2 border-white dark:border-r-darkMode-500">
                  <FontAwesomeIcon
                    icon={faLock}
                    className="text-gray-600 dark:text-gray-400"
                  />
                </div>
                <input
                  {...register("password")}
                  id="password"
                  name="password"
                  autoComplete="off"
                  type={viewUser === true ? "text" : "password"}
                  required
                  className="text-sm w-full bg-gray-300 dark:bg-darkMode-300 text-primary-500 dark:text-gray-400 placeholder:text-gray-600 dark:placeholder:text-gray-400 font-poppins px-4 py-3 rounded-r-md outline-none border border-gray-300 dark:border-darkMode-300 dark:focus:border-blue-400"
                  placeholder="Password"
                />
                <div className="flex items-center justify-center absolute right-4 top-4">
                  <FontAwesomeIcon
                    icon={viewUser === true ? faEyeSlash : faEye}
                    onClick={() => setViewUser(!viewUser)}
                    className="cursor-pointer transition-colors text-gray-600 dark:text-gray-400 hover:text-blue-400"
                  />
                </div>
              </div>
              <div className="grid grid-cols-12 py-2">
                <div className="col-span-7 flex items-center">
                  <input
                    id="remember-authenticated"
                    type="checkbox"
                    className="relative mr-2 w-4 h-4 rounded-sm appearance-none border border-gray-700 checked:border-blue-400 checked:bg-blue-400 checked:after:content-link after:absolute after:text-sm after:-left-[1px] after:scale-150 after:top-0 after:w-4 after:h-4 z-40 outline-none"
                  />
                  <label
                    htmlFor="remember-authenticated"
                    className="text-gray-400 text-sm ml-1 select-none"
                  >
                    Lembrar-me
                  </label>
                </div>
                <div className="col-span-5 flex justify-end">
                  <Link passHref href="/" prefetch={false}>
                    <a className="underline underline-offset-1 text-gray-400 text-sm">
                      esqueci minha senha
                    </a>
                  </Link>
                </div>
              </div>
              <div className="grid grid-cols-12 py-2">
                <button
                  type="submit"
                  className="col-span-12 w-full bg-blue-400 text-white dark:text-darkMode-500 font-poppins px-4 py-3 rounded-md outline-none transition-opacity hover:opacity-70"
                >
                  ENTRAR
                </button>
              </div>
              <div className="grid grid-cols-12 py-2">
                <p className="text-gray-700 dark:text-gray-500 col-span-12 text-sm text-center">
                  Não tenho uma conta,{" "}
                  <Link passHref href="/register" prefetch={true}>
                    <a className="underline underline-offset-1 text-blue-400">
                      criar agora
                    </a>
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePage;
