import { faCircle, faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "next-themes";
import { useEffect, useState } from "react";

export function ChangeTheme() {
  // const [darkMode, setDarkMode] = useState(true);
  const { theme, setTheme } = useTheme();
  const [themeC, setThemeC] = useState();

  useEffect(() => {
    setThemeC(theme || "light");
  }, [theme]);

  // function toggleTheme() {
  //   setDarkMode(!darkMode);
  //   if (localStorage.getItem("color-theme")) {
  //     if (localStorage.getItem("color-theme") === "light") {
  //       document.documentElement.classList.add("dark");
  //       localStorage.setItem("color-theme", "dark");
  //       setTheme("dark");
  //     } else {
  //       document.documentElement.classList.remove("dark");
  //       localStorage.setItem("color-theme", "light");
  //       setTheme("light");
  //     }
  //   } else {
  //     if (document.documentElement.classList.contains("dark")) {
  //       document.documentElement.classList.remove("dark");
  //       localStorage.setItem("color-theme", "light");
  //       setTheme("light");
  //     } else {
  //       document.documentElement.classList.add("dark");
  //       localStorage.setItem("color-theme", "dark");
  //       setTheme("dark");
  //     }
  //   }
  // }

  return (
    <>
      {!themeC && (
        <FontAwesomeIcon
          icon={faCircle}
          className="text-gray-200 animate-pulse ml-4 cursor-pointer w-4 h-4"
          onClick={() => {
            setTheme("light");
          }}
        />
      )}
      {themeC === "dark" && (
        <FontAwesomeIcon
          icon={faSun}
          className="text-yellow-600 ml-4 cursor-pointer w-4 h-4"
          onClick={() => {
            setTheme("light");
          }}
        />
      )}
      {themeC === "light" && (
        <FontAwesomeIcon
          icon={faMoon}
          className="text-blue-600 ml-4 cursor-pointer w-4 h-4"
          onClick={() => {
            setTheme("dark");
          }}
        />
      )}
    </>
  );
}
